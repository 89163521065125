import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Carousel from "../components/Carousel"

const Innovision = ({ data, location }) => {
  const url = `https://speedcambrasil.com.br${location.pathname}`

  let carouselImages = []
  data.allCarouselInnovision.edges.forEach(element => {
    carouselImages.push({ img: getImage(element["node"]) })
  })

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Innovision HD"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Innovision Probe II Lentes HD</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel picts={carouselImages} />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <p>Conjunto inclui as lentes: 9mm, 12mm, 16mm, 20mm e 32mm</p>
            <p>&nbsp;</p>
            <p>
              A Innovision Probe II foi desenvolvida especialmente para
              fotógrafos cujos projetos exigem lentes tubulares longas e imagens
              de maior resolução.
            </p>
            <p>
              A Innovision Probe II apresenta elementos de cristal de alta
              resolução e óptica de retransmissão para imagens com notável
              nitidez, campo plano e extrema profundidade de campo.
              Revestimentos múltiplos de última geração fornecem imagens nítidas
              e de baixa dispersão.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>

              <ul className="list-disc list-inside">
                <li>Velocidade: 35mm - T5.6, Video/16mm - T2.8</li>
                <li>Diâmetro: 1.7" (43.2mm)</li>
                <li>
                  Comprimento: 35mm - 18" (46cm), Video/16mm - 16" (41cm){" "}
                </li>
                <li>Peso: 3.5 lbs. (1.6kg) </li>
                <li>
                  Construído em alumínio de aviação com exterior anodizado
                  preto.
                </li>
                <li>
                  Accessórios opcionais: controle remoto de foco, filtros de
                  40.5mm, cases de transporte.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-12" />
      <div className="h-12" />
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <div className="m-auto">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/hdprobe.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      ficha técnica
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default Innovision

export const query = graphql`
  {
    allCarouselInnovision: allFile(
      filter: { relativePath: { regex: "/innovision-page/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
